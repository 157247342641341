import { useFormAction, useNavigation, useRouteError } from '@remix-run/react'
import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const oneYearFromNow = () => {
  const today = new Date()
  const oneYearFromNow = new Date(
    today.getFullYear() + 1,
    today.getMonth(),
    today.getDate()
  )
  const formattedDate = oneYearFromNow.toISOString().split('T')[0]
  return formattedDate
}

/**
 * Returns true if the current navigation is submitting the current route's
 * form. Defaults to the current route's form action and method POST.
 *
 * Defaults state to 'non-idle'
 *
 * NOTE: the default formAction will include query params, but the
 * navigation.formAction will not, so don't use the default formAction if you
 * want to know if a form is submitting without specific query params.
 */
export function useIsPending({
  formAction,
  formMethod = 'POST',
  state = 'non-idle',
}: {
  formAction?: string
  formMethod?: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE'
  state?: 'submitting' | 'loading' | 'non-idle'
} = {}) {
  const contextualFormAction = useFormAction()
  const navigation = useNavigation()
  const isPendingState =
    state === 'non-idle'
      ? navigation.state !== 'idle'
      : navigation.state === state
  return (
    isPendingState &&
    navigation.formAction === (formAction ?? contextualFormAction) &&
    navigation.formMethod === formMethod
  )
}

export const createReadableId = (amountOfCards: number) => {
  const currentYear = new Date().getFullYear()
  const lastTwoDigits = currentYear.toString().slice(-2)

  let newReadableId = undefined

  if (amountOfCards < 9) {
    newReadableId = `${lastTwoDigits}0000${amountOfCards + 1}`
  } else if (amountOfCards < 99) {
    newReadableId = `${lastTwoDigits}000${amountOfCards + 1}`
  } else if (amountOfCards < 999) {
    newReadableId = `${lastTwoDigits}00${amountOfCards + 1}`
  } else {
    newReadableId = `${lastTwoDigits}0${amountOfCards + 1}`
  }

  return newReadableId
}

export const dummyDataCards = [
  '21524a64-9bc7-4d9b-8f48-98cbce929071',
  'a12ebdc7-c6ce-48c4-9a06-de07ac28fa4c',
  '43fc1329-b37c-46ba-8b2f-4b6f84bf0772',
]

export const dummyDataHours = [
  '4724f5f5-ea95-4272-8728-23052d5e68aa',
  '7911d999-3a9c-4e92-a5a7-47048a3ddf47',
  '7b309bf2-f478-4399-afe1-9b7bbe24d8f5',
  '891a6fa9-ed9d-4de3-8b6f-2551fe374a53',
]

export const dummyDataClients = [
  'cc4620b7-9aae-455b-b7f1-79bbd404d5ba',
  'a6f0d3b0-d9d6-47c3-9dbc-d3a777842cae',
  '8fc5cbf6-90bc-41a7-9faf-fb93467781fe',
  '088d040d-698f-479f-87c9-86a26b18de0d',
  '498a65d6-e01d-416e-bfbf-f09dc94db0aa',
  '42aea556-5fab-411b-b78d-3bfbc387ee29',
]

/**
 * Combine multiple header objects into one (uses append so headers are not overridden)
 */
export function combineHeaders(
  ...headers: Array<ResponseInit['headers'] | null | undefined>
) {
  const combined = new Headers()
  for (const header of headers) {
    if (!header) continue
    for (const [key, value] of new Headers(header).entries()) {
      combined.append(key, value)
    }
  }
  return combined
}
